import focusWithin from 'focus-within';
import { $, $$, delegate, wrap } from './helper/utils';
import outline from './helper/outline';
import { MOBILMEDIA } from './helper/constants';

focusWithin(document);
outline();

const sliderMap = new Map();
const sliderDragging = new Map();
const isMobile = document.documentElement.classList.contains('is-mobile');

export default function () {
  let mobileNavLoaded = false;

  const loadMobileNav = () => {
    if ($('#mainnmenu-mobile') !== null) {
      import('./components/navigation/mobile')
        .then((mobileNav) => {
          mobileNavLoaded = true;
          return mobileNav.default();
        }).catch((err) => {
          console.warn(`Chunk navigation/mobile.js loading failed: ${err}`);
        });
    } else {
      mobileNavLoaded = true;
    }
  };

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadMobileNav();
    }
  });

  if (MOBILMEDIA.matches) {
    loadMobileNav();
  }
  console.log($('.thumbnail') !== null);
  if ($('.thumbnail') !== null) {
    import('./components/job-videos')
      .catch((err) => {
        console.warn(`Chunk /components/job-videos.js loading failed: ${err}`);
      });
  }

  if ($('.desktop-nav') !== null) {
    import('./components/navigation/desktop')
      .then((navigation) => navigation.default())
      .catch((err) => {
        console.warn(`Chunk navigation/desktop.js loading failed: ${err}`);
      });
  }

  if ($('form.searchbox') !== null) {
    import('./components/search/')
      .then((search) => search.default())
      .catch((err) => {
        console.warn(`Chunk search/index.js loading failed: ${err}`);
      });
  }

  const elements = $$('.company-info-item, .line-group .line-box');
  if (elements.length > 0) {
    import('./components/animations/triggerAnimations')
      .then((trigger) => trigger.default(elements))
      .catch((err) => {
        console.warn('Chunk animations/triggerAnimations.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null && isMobile === false) {
    import('./components/select')
      .then((name) => name.default())
      .catch((err) => {
        console.warn('Chunk select.js loading failed', err);
      });
  }

  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.media-images') !== null) {
    import('./components/mediaDownload')
      .then((mediaDownload) => mediaDownload.default())
      .catch((err) => {
        console.warn('Chunk mediaDownload.js loading failed', err);
      });
  }

  if ($('.lightbox') !== null) {
    import('./components/lightbox')
      .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }

  if ($('.company-info-item') !== null) {
    import('./components/animations/counter')
      .then((counter) => {
        const counterElements = $$('.company-info-item h3, .company-info-item .item-change span');
        counter.default(counterElements);
      })
      .catch((err) => {
        console.warn('Chunk /animations/counter.js loading failed', err);
      });
  }

  if ($('.quicklinks') !== null) {
    import('./components/quicklinks')
      .then((quicklinks) => quicklinks.default())
      .catch((err) => {
        console.warn('Chunk /quicklinks.js loading failed', err);
      });
  }

  if ($('.filter') !== null) {
    import('./components/filters/')
      .then((filters) => filters.default())
      .catch((err) => {
        console.warn('Chunk /filters.js loading failed', err);
      });
  }

  if ($('.leading') !== null) {
    import('./pages/leading')
      .then((leading) => leading.default())
      .catch((err) => {
        console.warn('Chunk /leading.js loading failed', err);
      });
  }

  const collapseMap = new Map();
  const showsMore = new Set();
  const toggleText = function () {
    if (showsMore.has(this)) {
      this.textContent = collapseMap.get(this).more;
      showsMore.delete(this);
    } else {
      this.textContent = collapseMap.get(this).less;
      showsMore.add(this);
    }
  };

  if ($('.nav-tabs') !== null || $('[data-toggle="collapse"]') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }

        const collapseBtns = $$('.btn-collapse');
        for (let i = 0; i < collapseBtns.length; i++) {
          const el = collapseBtns[i];
          const target = $(el.getAttribute('href'));

          if (target !== null) {
            bsn.default.Collapse(el);
            el.addEventListener('click', el.Collapse.toggle);
          }
        }

        // toggle show more button text
        const showHide = $$('[data-showless]:not(.event-show-all)');
        for (let i = 0; i < showHide.length; i++) {
          const el = showHide[i];

          collapseMap.set(el, {
            more: el.textContent,
            less: el.dataset.showless,
          });
          el.addEventListener('click', toggleText);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }

  if ($('main table') !== null) {
    const tables = $$('main table');

    for (let i = 0; i < tables.length; i++) {
      const el = tables[i];

      // skip elements that were already wrapped
      if (el.parentElement.classList.contains('table')) {
        continue;
      }

      const wrapper = document.createElement('div');
      wrapper.classList.add('table');
      wrap(el, wrapper);
    }
  }

  if ($('.apprenticeship-scroll button.btn') !== null) {
    import('./pages/apprenticeship')
      .then((apprenticeship) => apprenticeship.default())
      .catch((err) => {
        console.warn('Chunk /apprenticeship.js loading failed', err);
      });
  }

  if ($('.smooth-scroll') !== null) {
    import('./components/smoothScroll')
      .then((smoothScroll) => smoothScroll.default())
      .catch((err) => {
        console.warn('Chunk /smoothScroll.js loading failed', err);
      });
  }

  if ($('.sustainability') !== null) {
    import('./components/sustainability')
      .catch((err) => {
        console.warn('Chunk /components/sustainability.js loading failed', err);
      });
  }

  if ($('.suppliers-form-progress') !== null) {
    import('./components/suppliers/suppliersForm')
      .then((suppliersForm) => suppliersForm.default())
      .catch((err) => {
        console.warn('Chunk /components/suppliers/suppliersForm.js loading failed', err);
      });
  }

  if ($('.contact-form') !== null) {
    import('./components/contact/contactForm')
      .then((contactForm) => contactForm.default())
      .catch((err) => {
        console.warn('Chunk /components/contact/contactForm.js loading failed', err);
      });
  }

  if ($('.brand-matrix') !== null) {
    import('./components/brandMatrix')
      .catch((err) => {
        console.warn('Chunk /components/brandMatrix.js loading failed', err);
      });
  }

  if ($('.hr-strategy-faq') !== null) {
    import('./pages/hr-faq')
      .catch((err) => {
        console.warn('Chunk /pages/hr-faq.js loading failed', err);
      });
  }

  if ($('.event-day-wrapper') !== null) {
    import('./components/eventGallery')
      .catch((err) => {
        console.warn('Chunk /components/eventGallery.js loading failed', err);
      });
  }

  if ($('.open-video-modal') !== null) {
    import('./components/videoModal')
      .catch((err) => {
        console.warn('Chunk /components/videoModal.js loading failed', err);
      });
  }
}
